import React from "react";
import i18n from "../i18n";
import { GB, TR } from "country-flag-icons/react/3x2";

const LanguageSwitcher = () => {
  const changeLanguage = (inp) => {
    window.localStorage.setItem("lang", inp);
    i18n.changeLanguage(inp);
  };

  return (
    <div className="flex md:gap-5 max-md:flex-col max-md:gap-2">
      <GB
        title="ENG"
        onClick={() => changeLanguage("en")}
        className="h-[1rem] cursor-pointer"
      />

      <TR
        title="TR"
        onClick={() => changeLanguage("tr")}
        className="h-[1rem] cursor-pointer"
      />
    </div>
  );
};

export default LanguageSwitcher;
