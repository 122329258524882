import Navbar from "./components/Navbar";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Products from "./pages/Products";
import Services from "./pages/Services";
import Contact from "./pages/Contact";
import Footer from "./components/Footer";
import MobileNavbar from "./components/MobileNavbar";
import { useState } from "react";
import References from "./pages/References";
import ScrollToTop from "./pages/ScrollToTop";

function App() {
  const [mobileNavbarVisibility, setMobileNavbarVisibility] = useState(false);

  const navbarSwitcher = (actionType) => {
    if (actionType === "open") {
      setMobileNavbarVisibility(true);
    } else if (actionType === "close") {
      setMobileNavbarVisibility(false);
    }
  };

  return (
    <div className="App">
      <Router>
        <MobileNavbar
          visibility={mobileNavbarVisibility}
          navbarSwitcherFunction={navbarSwitcher}
        />
        <Navbar
          navbarSwitcherFunction={navbarSwitcher}
          mobileNavbarVisibility={mobileNavbarVisibility}
        />
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/hakkimizda" element={<About />} />
          <Route path="/urunler" element={<Products />} />
          <Route path="/hizmetler" element={<Services />} />
          <Route path="/iletisim" element={<Contact />} />
          <Route path="/referanslar" element={<References />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
