import React from "react";
import { useTranslation } from "react-i18next";
import { BiSolidMap } from "react-icons/bi";
import { FaPhone } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";
import { FaMailBulk } from "react-icons/fa";
import { Link } from "react-router-dom";

const Contact = () => {
  const { t } = useTranslation();
  return (
    <section className="min-h-[92vh]  xl:w-[96vw] md:w-[96vw] mx-auto border-t pt-[3vh] max-md:mb-16 pagebottom">
      <h1 className="md:text-[2rem] max-md:text-center   max-md:text-[1.7rem] font-[400]">
        {t("menuItem6")}
      </h1>
      <div className="grid grid-cols-3 md:mt-32 max-md:mt-10 max-md:gap-14">
        <div className="col-span-1 max-md:col-span-3 flex flex-col items-center md:gap-10 max-md:gap-2">
          <BiSolidMap className="text-[2.5rem]" />
          <p className="lg:text-2xl md:text-xl font-[300] max-md:text-lg text-center">
            <span className="font-[400] md:text-2xl max-md:text-xl">
              Head Office
            </span>
            <br />
            Öğretmenevleri Mahallesi
            <br />
            Atatürk Bulvarı
            <br />
            No: 76 Konyaaltı
            <br />
            ANTALYA/TÜRKİYE
          </p>
        </div>
        <div className="col-span-1 max-md:col-span-3  flex flex-col items-center md:gap-10 max-md:gap-2">
          <div className="flex gap-5 items-center">
            <Link className="hover:text-[#525151]" to="tel:905076627373">
              <FaPhone className="text-3xl" />
            </Link>
            <Link
              className="hover:text-[#525151]"
              to="https://wa.me/905076627373"
            >
              <FaWhatsapp className="text-[2.2rem]" />
            </Link>
          </div>
          <Link
            className="lg:text-3xl md:text-2xl max-md:text-xl font-[300]"
            to="tel:905076627373"
          >
            +90 507 662 73 73
          </Link>
        </div>
        <div className="col-span-1 max-md:col-span-3 flex flex-col items-center md:gap-10 max-md:gap-2">
          <Link
            className="hover:text-[#525151]"
            to="mailto:info@trepack.com.tr"
          >
            <FaMailBulk className="text-[2rem]" />
          </Link>
          <Link
            className="lg:text-3xl md:text-2xl max-md:text-xl font-[300]"
            to="mailto:info@trepack.com.tr"
          >
            info@trepack.com.tr
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Contact;
