import React from "react";
import Logo from "./Logo";
import LanguageSwitcher from "./LanguageSwitcher";
import Menu from "./Menu";
import { FaPhone } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";
import { FaMailBulk } from "react-icons/fa";
import { Link } from "react-router-dom";
import { IoReorderThree } from "react-icons/io5";

const Navbar = ({ navbarSwitcherFunction, mobileNavbarVisibility }) => {
  return (
    <header className="flex justify-between xl:px-[2%] md:px-[1%] max-md:px-[1%] navbarHeight w-full  relative h-[8vh] max-md:h-auto max-md:py-4">
      <IoReorderThree
        className={`max-[1199px]:text-[2.2rem] self-center min-[1200px]:hidden hamburgerMenuIconSize  ${
          mobileNavbarVisibility ? "invisible pointer-events-none" : ""
        }`}
        onClick={() => navbarSwitcherFunction("open")}
      />

      <Menu />
      <Logo />
      <div className="flex items-center gap-10 max-md:gap-3 xl:text-lg lg:text-base max-md:text-xs">
        <div className="flex items-center gap-2">
          <Link
            className="bg-black p-2 rounded-full hover:bg-[#525151] transition-all"
            to="tel:905076627373"
          >
            <FaPhone className="text-white topContactIcons" />
          </Link>
          <Link
            className="bg-black p-2 rounded-full hover:bg-[#525151] transition-all"
            to="https://wa.me/905076627373"
          >
            <FaWhatsapp className="text-white topContactIcons" />
          </Link>
          <Link
            className="bg-black p-2 rounded-full hover:bg-[#525151] transition-all"
            to="mailto:info@trepack.com.tr"
          >
            <FaMailBulk className="text-white topContactIcons" />
          </Link>
        </div>
        <LanguageSwitcher />
      </div>
    </header>
  );
};

export default Navbar;
