import React from "react";
import { useTranslation } from "react-i18next";

const About = () => {
  const { t } = useTranslation();
  return (
    <section className="min-h-[92vh] xl:w-[96vw] md:w-[96vw] max-md:w-[100vw] max-md:px-5 max-md:text-center lg: mx-auto border-t pt-[3vh] flex flex-col max-md:mb-16 pagebottom">
      <h1 className="md:text-[2rem] max-md:text-[1.7rem] font-[400]">
        {t("menuItem2")}
      </h1>
      <div className="flex flex-1">
        <div className="md:w-[50%] max-md:w-[100%] pt-5 flex flex-col">
          <div className=" flex flex-col gap-2 text-lg font-[300]">
            <p className="leading-8">{t("aboutUsParagraph1")}</p>
            <p className="leading-8">{t("aboutUsParagraph2")}</p>
            <p className="leading-8">{t("aboutUsParagraph3")}</p>
          </div>
        </div>
        <div className="w-[50%] flex justify-center max-md:hidden">
          <img
            src="images/aboutPhoto.jpg"
            className="rounded-lg min-[1025px]:h-[70vh] md:h-[45vh] aboutImage"
            alt=""
          />
        </div>
      </div>
    </section>
  );
};

export default About;
