import React from "react";
import { Link } from "react-router-dom";
const Logo = () => {
  return (
    <div className="md:absolute md:left-[50%] md:top-[50%] md:-translate-x-[50%] md:-translate-y-[50%] max-md:flex max-md:flex-col max-md:justify-center logoPosition">
      <Link to="/">
        <img
          src="images/LOGO.png"
          className="h-[6vh] max-sm:h-[2.5rem] w-auto logoSize"
          alt="Trepack Logo"
        />
      </Link>
      {/* <Link to="/">
        <img
          src="images/mobileLogo.png"
          className="h-[4vh] w-auto md:hidden"
          alt="Trepack Logo"
        />
      </Link> */}
    </div>
  );
};

export default Logo;
