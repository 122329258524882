import React from "react";
import { useTranslation } from "react-i18next";

const Services = () => {
  const { t } = useTranslation();
  const productIndexes = Array.from({ length: 10 }, (_, index) => index + 1);
  return (
    <section className="min-h-[92vh]  xl:w-[96vw] md:w-[96vw] mx-auto border-t pt-[3vh]">
      <h1 className="md:text-[2rem] max-md:text-center  max-md:text-[1.7rem] font-[400]">
        {t("menuItem4")}
      </h1>
      <div className="grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 max-md:px-5 gap-5 cols  font-[300] pt-5 pb-[15vh]">
        {productIndexes.map((val) => (
          <div
            key={val}
            className="col-span-1  border border-gray-300 overflow-hidden"
          >
            <img
              src={`images/servicesImages/${val}.jpg`}
              alt=""
              className="w-full h-[35vh] object-cover"
            />
            {/* object-cover class'ı ver yukardaki img' tag'ine */}
            <h2 className="text-center font-[400]   md:text-2xl max-md:text-xl py-5">
              {t(`serviceName${val}`)}
            </h2>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Services;
