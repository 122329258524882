import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Menu = () => {
  const { t } = useTranslation();
  return (
    <div className="flex items-center gap-5 font-semibold text-black xl:text-base lg:text-[0.9rem] max-[1199px]:hidden">
      <Link
        to="/"
        className="cursor-pointer font-[400] hover:border-b border-black transition-all duration-75"
      >
        {t("menuItem1")}
      </Link>
      <Link
        to="/hakkimizda"
        className="cursor-pointer  font-[400] hover:border-b border-black transition-all duration-75"
      >
        {t("menuItem2")}
      </Link>
      <Link
        to="/urunler"
        className="cursor-pointer font-[400] hover:border-b border-black transition-all duration-75"
      >
        {t("menuItem3")}
      </Link>
      <Link
        to="/hizmetler"
        className="cursor-pointer font-[400] hover:border-b border-black transition-all duration-75"
      >
        {t("menuItem4")}
      </Link>
      <Link
        to="/referanslar"
        className="cursor-pointer font-[400] hover:border-b border-black transition-all duration-75"
      >
        {t("menuItem5")}
      </Link>
      <Link
        to="/iletisim"
        className="cursor-pointer font-[400] hover:border-b border-black transition-all duration-75"
      >
        {t("menuItem6")}
      </Link>
    </div>
  );
};

export default Menu;
