import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FaPhone } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";
import { FaMailBulk } from "react-icons/fa";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <footer className="bg-black max-md:py-8">
      <div className="flex md:h-[35vh] justify-center">
        <div className="flex-col max-md:gap-4 items-center flex justify-evenly max-md:text-center">
          <img src="/images/LOGO2.png" alt="" className="h-[6vh] w-auto" />
          <div className="flex max-md:flex-col md:gap-5 gap-4 text-[#DADADA] justify-center font-[400] text-xl">
            <Link to="/" className="hover:text-white">
              {t("menuItem1")}
            </Link>
            <Link to="/hakkimizda" className="hover:text-white">
              {t("menuItem2")}
            </Link>
            <Link to="/urunler" className="hover:text-white">
              {t("menuItem3")}
            </Link>
            <Link to="/hizmetler" className="hover:text-white">
              {t("menuItem4")}
            </Link>
            <Link to="/referanslar" className="hover:text-white">
              {t("menuItem5")}
            </Link>
            <Link to="/iletisim" className="hover:text-white">
              {t("menuItem6")}
            </Link>
          </div>

          <div className="flex items-center gap-6 justify-center">
            <Link to="tel:905076627373" target="_blank">
              <FaPhone className="text-[#DADADA] text-xl hover:text-white" />
            </Link>
            <Link to="https://wa.me/905076627373" target="_blank">
              <FaWhatsapp className="text-[#DADADA] text-2xl hover:text-white" />
            </Link>
            <Link to="mailto:info@trepack.com.tr" target="_blank">
              <FaMailBulk className="text-[#DADADA] text-xl hover:text-white" />
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
