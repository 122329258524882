import React from "react";
import { IoMdClose } from "react-icons/io";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const MobileNavbar = ({ visibility, navbarSwitcherFunction }) => {
  const { t } = useTranslation();
  return (
    <div
      className={`fixed top-0 left-0 h-[100vh] mobileNavbarBG min-[1199px]:hidden z-10 w-full flex flex-col landscapeMobilNavbar max-md:text-[2rem] md:text-[3rem] mobileNavbarTextSize max-md:gap-5 md:gap-7 items-center justify-center ${
        visibility ? "" : "-translate-x-[100%]"
      } transition-all`}
    >
      <IoMdClose
        className="absolute top-6 right-6 text-[#DADADA] md:text-[3rem] mobileNavbarCloseIconSize "
        onClick={() => navbarSwitcherFunction("close")}
      />
      <Link
        to="/"
        className="cursor-pointer font-[300] text-[#DADADA]"
        onClick={() => navbarSwitcherFunction("close")}
      >
        {t("menuItem1")}
      </Link>
      <Link
        to="/hakkimizda"
        className="cursor-pointer  font-[300] text-[#DADADA] "
        onClick={() => navbarSwitcherFunction("close")}
      >
        {t("menuItem2")}
      </Link>
      <Link
        to="/urunler"
        className="cursor-pointer font-[300] text-[#DADADA]"
        onClick={() => navbarSwitcherFunction("close")}
      >
        {t("menuItem3")}
      </Link>
      <Link
        to="/hizmetler"
        className="cursor-pointer font-[300] hover:border-b border-black transition-all duration-75 text-[#DADADA]"
        onClick={() => navbarSwitcherFunction("close")}
      >
        {t("menuItem4")}
      </Link>
      <Link
        to="/referanslar"
        className="cursor-pointer font-[300] hover:border-b border-black transition-all duration-75 text-[#DADADA]"
        onClick={() => navbarSwitcherFunction("close")}
      >
        {t("menuItem5")}
      </Link>
      <Link
        to="/iletisim"
        className="cursor-pointer font-[300] hover:border-b border-black transition-all duration-75 text-[#DADADA]"
        onClick={() => navbarSwitcherFunction("close")}
      >
        {t("menuItem6")}
      </Link>
    </div>
  );
};

export default MobileNavbar;
