import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

import { useTranslation } from "react-i18next";
const Home = () => {
  const { t } = useTranslation();
  const carouselImageIndexes = Array.from(
    { length: 4 },
    (_, index) => index + 1
  );
  return (
    <section className="md:min-h-[92vh] homeSectionSize max-md:h-auto">
      <Carousel
        showThumbs={false}
        swipeable={true}
        showIndicators={false}
        stopOnHover={false}
        showStatus={false}
        infiniteLoop={true}
        autoPlay
      >
        {carouselImageIndexes.map((val) => (
          <div className="relative" key={val}>
            <img
              src={`images/carouselImages/${val}.jpg`}
              className="md:h-[92vh] max-md:h-[45vh] w-auto object-cover carouselImage"
              alt={`Trepak Etiket ${val}`}
            />
            <p className="absolute bottom-0 md:left-[50%] md:-translate-x-[50%]  md:py-5 max-md:py-1 text-[1.7rem] text-white customBG lg:px-10 md:px-5 md:rounded-t-xl max-md:w-full font-[400] font-[bovaNova] text-center">
              {t(`carousel${val}`)}
            </p>
          </div>
        ))}
      </Carousel>
      <div className="flex flex-col w-full px-5 gap-4 max-[1000px]:py-8 portrait:md:hidden landscape:min-[1000px]:hidden font-[300]">
        <h2 className="text-xl font-[500]">{t("menuItem2")}</h2>
        <p>{t("aboutUsParagraph1")}</p>
        <p>{t("aboutUsParagraph3")}</p>
      </div>
    </section>
  );
};

export default Home;
